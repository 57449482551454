html {
    scroll-behavior: smooth;
}

html, body {
    height: 100% !important;
    padding: 0;
    margin:0;
    font-family: "Open Sans", sans-serif;
}

body {
    margin: 0;
}

#root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    --primary-color: #0099cc;
    --border-radius: 10px;
    font-family: "Open Sans", sans-serif;
    --text-color: #747473;
    color: #747473;
    font-size: 14px;
    box-sizing: border-box;
}

.p-dialog {
    --primary-color: #0099cc;
}

.ui-widget {
    font-family: "Open Sans", sans-serif;
}

hr {
    border: solid 1px #e0e0e0;
}

a {
    text-decoration: none;
    color: inherit;
}

.text-xss {
    font-size: 0.5rem !important;
}

.mw-100 {
    max-width: 100vw
}

.tk-bg-white {
    background-color: #ffffff;
}

.p-card {
    border-radius: 10px !important;
}

.word-wrap {
    word-break: break-word;
}
.p-component {
    font-family: "Open Sans", sans-serif !important;
}

h3 {
    font-size: 1.4em;
}
